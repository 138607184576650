<template>
  <div>
    <p class="text form__text">{{ title }}</p>
    <select :class="custom_class" class="input">
      <option
        @click="emitter(option.id)"
        v-for="option in options"
        :key="option.id"
        :value="option.id"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Selector",
  props: ["title", "custom_class", "options", "field"],
  methods: {
    emitter(id) {
      this.$emit("value-input", {
        field: this.field,
        value: id,
      });
    },
  },
};
</script>