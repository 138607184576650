<template>
  <div class="wrapper">
    <UnAuthHeader />
    <main class="page">
      <div class="container-615">
        <section class="entity">
          <h1 class="entity__title title">Регистрация</h1>
          <form class="entity__form form" @submit.prevent="sendForm">
            <article class="entity__flex">
              <Input
                @value-input="saveValue"
                title="Фамилия"
                field="last_name"
                type="text"
                placeholder="Иванов"
                custom_class="form__last-name"
                :error="errors.last_name"
              />
              <Input
                @value-input="saveValue"
                title="Имя"
                field="first_name"
                type="text"
                placeholder="Иван"
                custom_class="form__name"
                :error="errors.first_name"
              />
            </article>
            <article class="entity__flex">
              <Phone
                title="Номер телефона"
                @value-input="saveValue"
                field="phone"
                :error="errors.phone"
              />
              <Input
                @value-input="saveValue"
                title="Введите свой email"
                field="email"
                type="email"
                placeholder="test@imigrata.com"
                custom_class="form__email"
                :error="errors.email"
              />
            </article>
            <article class="entity__flex">
              <Password
                title="Введите пароль"
                @value-input="saveValue"
                field="password"
                :error="errors.password"
              />
              <Password
                title="Введите пароль еще раз"
                @value-input="saveValue"
                field="password_repeat"
                :error="errors.password_repeat"
              />
            </article>
            <article class="entity__flex">
              <Selector
                @value-input="saveValue"
                title="Страна"
                field="country_id"
                custom_class="form__select"
                :options="available_countries"
              />
              <Input
                @value-input="saveValue"
                title="Ссылка на сайт"
                field="site_url"
                type="text"
                placeholder="https://"
                custom_class="form__site"
                :error="errors.site_url"
              />
            </article>
            <article>
              <Input
                @value-input="saveValue"
                title="Статус и номер в коллегии адвокатов"
                field="collegium_status"
                type="text"
                placeholder="Text"
                custom_class="form__status"
                :error="errors.collegium_status"
              />
            </article>
            <article>
              <input
                type="submit"
                placeholder=""
                class="form__submit"
                value="Зарегистрироваться"
              />
            </article>
          </form>
          <div class="entity__already-login">
            <router-link :to="{ path: '/auth' }" class="entity__already-login-link"
              >Уже зарегистрирован</router-link
            >
          </div>
        </section>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../../helpers/api";
import { Validation } from "../../helpers/validation";

import UnAuthHeader from "../../components/headers/UnAuth.vue";
import Input from "../../components/utils/Input.vue";
import Selector from "../../components/utils/Selector.vue";
import Phone from "../../components/utils/Phone.vue";
import Password from "../../components/utils/Password.vue";
import Footer from "../../components/Footer.vue";

import { mapActions } from 'vuex';

export default {
  name: "Auth",
  components: {
    UnAuthHeader,
    Input,
    Selector,
    Phone,
    Password,
    Footer,
  },
  data() {
    return {
      available_countries: [],
      values: {
        email: "",
        password: "",
        password_repeat: "",
        first_name: "",
        last_name: "",
        phone: "",
        country_id: 0,
        site_url: "",
        collegium_status: "",
      },
      errors: {
        email: "",
        password: "",
        password_repeat: "",
        first_name: "",
        last_name: "",
        phone: "",
        country_id: "",
        site_url: "",
        collegium_status: "",
      },
    };
  },
  async mounted() {
    try {
      const response = await API.send("getCountries", {}, "GET");

      if (response.status !== 0) {
        alert(response.user_message);
        return;
      }

      this.available_countries = response.countries;
    } catch (e) {
      alert("Произошла неизвестная ошибка. Попробуйте позже.");
      console.error(e);
    }
  },
  methods: {
    ...mapActions(['setUser']),
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm() {
      this.errors.email = Validation.checkEmail(this.values.email);
      this.errors.password = Validation.checkPassword(this.values.password);
      this.errors.password_repeat =
        this.values.password === this.values.password_repeat
          ? ""
          : "Пароли не совпадают";
      this.errors.first_name = Validation.checkName(this.values.first_name);
      this.errors.last_name = Validation.checkName(this.values.last_name);
      this.errors.phone = Validation.checkPhone(this.values.phone);
      this.errors.country_id = this.values.country_id <= 0;
      this.errors.site_url = Validation.checkUrl(this.values.site_url);
      this.errors.collegium_status = Validation.checkText(this.values.collegium_status);

      if (Object.values(this.errors).some((result) => result.length > 0))
        return;

      try {
        const response = await API.send("registerLawyer", this.values, "POST");

        if (response.status !== 0) {
          this.errors.email = response.user_message;
          return;
        }

        alert('Вы успешно зарегистрированы. На вашу почту ушло письмо с подтверждением.');

        this.setUser(response.user);  
        this.$router.push(`/profile`);
      } catch (e) {
        this.errors.email = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
  },
};
</script>
